import { ContinueWithGoogle } from '../../components/ContinueWithGoogle'
import { FormEvent, useEffect, useState } from 'react'
import { getUrlParameters, persistUrlParameters } from '../../helpers/urls'
import { Input } from '../../components/FormElements/Input'
import { Separator } from '../../components/Separator'
import { setNewAccessToken } from '../../infrastructure/apis'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useWhoAmI } from '../../hooks/queries/useWhoAmI'

export const LoginMethods = () => {
    const { t } = useTranslation('signIn')
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [error, setError] = useState<string>()
    const onError = ({ response }: any) => setError(response.data.message)

    useEffect(() => {
        persistUrlParameters()

        const { token, courseId } = getUrlParameters()

        if (!token) return

        setNewAccessToken(token as string)
        navigate(courseId ? `/dashboard/${courseId}` : '/')

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { refetch, isFetching } = useWhoAmI({
        email,
        onError,
    })

    const onSubmit = (event?: FormEvent<HTMLFormElement>) => {
        event?.preventDefault()
        refetch()
    }

    return (
        <>
            <h1 className="title">{t('Log in')}</h1>

            <ContinueWithGoogle />

            <form onSubmit={onSubmit}>
                <Input
                    {...{
                        id: 'email',
                        name: 'email',
                        label: t('Email'),
                        autoComplete: 'username',
                        type: 'email',
                        placeholder: 'username@mail.com',
                        required: true,
                        disabled: isFetching,
                        value: email ?? '',
                        onChange: ({ target: { value } }) => {
                            setError(undefined)
                            setEmail(value)
                        },
                        error: t(error!) ?? undefined,
                    }}
                />
                <br />

                <button
                    className="primary w-100"
                    children={t('Log in')}
                    disabled={!email || isFetching || !!error}
                />
            </form>

            <br />
        </>
    )
}

export default LoginMethods
