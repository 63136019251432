import { GACategory, SendGAEvent } from '../../../helpers/GAEvents'
import { createUser, migrateUser } from '../../../pages/register/api-register'

import Axios from 'axios'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useIntercom } from '../../useIntercom'

export const useCreateUser = () => {
    const { t } = useTranslation('signUp')
    const { executeRecaptcha } = useGoogleReCaptcha()
    const navigate = useNavigate()
    const { clientUpdate, newMessage } = useIntercom()
    const queryClient = useQueryClient()

    const { mutate: create, ...rest } = useMutation({
        retry: false,
        mutationFn: async (data: Parameters<typeof createUser>[0]) => {
            const captcha_token = (await executeRecaptcha?.('createUser')) ?? ''
            return createUser(data, captcha_token)
        },

        onSuccess: () => {
            navigate(`/registro/2`)
        },

        onError: (err, { email }) => {
            const userExists = (err as any)?.response?.status === 409

            console.log({ err })

            if (userExists) return navigate(`/login/credential?email=${email}`)

            SendGAEvent(
                GACategory.ERROR,
                `An error has occurred while a user is trying to register.`
            )
            clientUpdate({
                email,
                language_override: 'es',
            })
            newMessage(t('Hello, I need help to register in Egg!'))
            navigate(`/registro/error?email=${email}`)
        },
    })

    const { mutate: migrate, mutateAsync: migrateAsync } = useMutation({
        mutationFn: migrateUser,
        onSuccess: () => navigate(`/registro/2`),
        onError: (err, { email }) => {
            SendGAEvent(
                GACategory.ERROR,
                `An error has occurred while a user is trying to migrate.`
            )

            clientUpdate({
                email,
                language_override: 'es',
            })
            newMessage(t('Hello, I need help to migrate my user info in Egg!'))

            if (Axios.isAxiosError(err))
                err.response?.status === 409 && navigate(`/registro/error?email=${email}`)
        },
    })
    return { create, migrate, migrateAsync, ...rest }
}
