import { useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { CourseSteps } from '../components/DashModules/CourseSteps'
import { useUserCourses } from '../hooks/queries/useUserCourses'
import { useOpenMeet } from '../hooks/useOpenMeet'
import { handlePresent, useSetPresent } from '../hooks/useSetPresent'
import { setNewAccessToken } from '../infrastructure/apis'
import { useStudyPlan } from './course/hooks/useStudyPlan'

const Dashboard = () => {
    const navigate = useNavigate()
    const { getCourseData } = useUserCourses()
    const { courseId } = useParams()
    const course = getCourseData(courseId)
    const { openMeet } = useOpenMeet(courseId)
    const [params] = useSearchParams()
    const hasOpenMeet = params.get('meet')
    const hasStep = params.get('step')
    const { todayContent } = useStudyPlan(courseId)
    const firstStepId = todayContent.step?._id

    useSetPresent(courseId)

    useEffect(() => {
        if (!course) return
        setNewAccessToken(course['access-token'])
        window.history.replaceState({}, '', window.location.pathname)
        if (hasOpenMeet) {
            handlePresent(course.courseId)
            openMeet()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Open first step if hasStep is true
    useEffect(() => {
        if (!hasStep || !firstStepId || !course) return
        if (hasStep && firstStepId) {
            handlePresent(course.courseId)
            navigate(`/content/step/${firstStepId}`)
        }
    }, [course, hasStep, firstStepId])

    return <CourseSteps />
}

export default Dashboard
